import 'bootstrap/dist/css/bootstrap.min.css';
import './Kontakt.css';
import * as emailjs from 'emailjs-com';
import facebook from '../assets/facebook-icon.png';
import github from '../assets/github-icon.png';
import linkedin from '../assets/linkedin-icon.png';

function Kontakt() {

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_gv5a0vg', 'template_9x5bjwq', e.target, 'jKlCIyjf7R0zAvY65')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };

    return(
        <div id="kontakt" className="kontakt-container">
            <h2 className="title">KONTAKT</h2>
            <p className="subtitle">Interesseret i at høre mere?</p>
            <div className="kontakt-wrapper">
                <div className="contact-form">
                    <p>Kontakt mig her:</p>
                    <form onSubmit={sendEmail}> 
                        <div className="row mx-auto">
                            <div className="col-8 form-group mx-auto">
                                <input type="text" className="form-control" placeholder="Navn" name="name" required/>
                            </div>
                            <div className="col-8 form-group pt-2 mx-auto">
                                <input type="email" className="form-control" placeholder="Email adresse" name="email" required/>
                            </div>
                            <div className="col-8 form-group pt-2 mx-auto">
                                <textarea className="form-control" id="" cols="30" rows="8" placeholder="Din besked" name="message" required></textarea>
                            </div>
                            <div className="col-8 pt-3 mx-auto">
                                <input type="submit" className="btn btn-secondary" value="Send besked"></input>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="socialmedia">
                    <p>Eller fang mig på de sociale medier:</p>
                    <a href="https://www.facebook.com/esengul.yildirim.391/" target="_blank"><img src={facebook} alt="facebook icon link to my Facebook page" height="80px" /></a>
                    <a href="https://github.com/esenyil" target="_blank"><img src={github} alt="facebook icon link to my Github page" height="80px" /></a>
                    <a href="https://www.linkedin.com/in/esengülyildirim" target="_blank"><img src={linkedin} alt="facebook icon link to my LinkedIn page" height="80px" /></a>
                </div>
                <small>&copy; copyright {document.innerHTML = new Date().getFullYear()} Esengül Yildirim</small>
            </div>
        </div>
    )
}
export default Kontakt;