import './OmMig.css';
import imgOfMe from '../assets/esen.jpg';
import CV from '../assets/CV.pdf';

function OmMig() {
    return(
        <div id="om-mig" class="ommig-container">
            <h2 class="title">OM MIG</h2>
            <div className="ommig-content">
                <img src={imgOfMe} className="imgOfMe" alt="Billede af Esengül Yildirim" height="300px"/>
                <div className='line'></div>
                <p className='ommig-description'>
                    Jeg brænder for at kode, designe og udvikle hjemmesider og elsker udfordringen i det. Som person er jeg detaljeorienteret, struktureret og god til at danne mig et overblik i et travlt miljø.
                    <br/> 
                    Udover at jeg har en kæmpe interesse og faglig tilgang til webudvikling, har jeg også fokus på grundlæggende værdier som åbenhed, forståelse, tydelighed, humor og respekt for forskellighed, da det er nogle af de vigtigste værktøjer i tilgangen til et godt produkt/resultat.
                    <br/> 
                    Jeg brænder for at udvikle mine kompetencer og sætter derfor stort fokus på at holde mig opdateret og lære nye ting.
                    </p>
            </div>
            <a href={CV} target="_blank" class="ommig-button button btn btn-secondary">Se mit CV</a>
        </div>
    )
}
export default OmMig;