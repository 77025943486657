import 'bootstrap/dist/css/bootstrap.min.css';
import './Intro.css';
import { HashLink } from 'react-router-hash-link';
import CV from '../assets/CV.pdf';

function Intro() {
    return(
        <div id="intro" className='intro-container'>
            <div className='intro-text'>
                <p className='intro-p-one'>Hej, mit navn er </p>
                <h1 className='intro-name'>Esengül Yildirim</h1>
                <p className='intro-p-two'>Jeg er studerende på CPH Business Lyngby, hvor jeg læser webudvikling. På uddannelsen har jeg beskæftiget mig med Frontend, Backend og User Experience.</p>
            </div>
            <div className="intro-button">
                <HashLink smooth to={"/#portfolio"}><button className='portfolio-button btn btn-secondary'>Portfolio</button></HashLink>
                <HashLink smooth to={"/#kompetencer"}><button className='kompetencer-button btn btn-secondary'>Kompetencer</button></HashLink>
                <a href={CV} className='cv-button btn btn-secondary' download>Download CV</a>
            </div>
        </div>
    )
}
export default Intro;