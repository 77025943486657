import IconLink from './IconLink';
import data from '../data';
import './Kompetencer.css';

function Kompetencer() {
    
    const iconLink = data.map(item => {
        return <IconLink key={item.id} icon={item.icon} text={item.text} />
    })

    return(
        <div id="kompetencer" className='kompentencer-container'>
            <p className='title'>KOMPETENCER</p>
            <div className="iconlink">
                {iconLink}
            </div>
        </div>
    )
}
export default Kompetencer;